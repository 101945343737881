import { productService } from '@/api';
import { PlatformProductResource } from '@/resource/model';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { Paging, Query } from '@/api/base';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTable, OsTableQuery, OsPagination } from '@/components';
import { messageError } from '@/utils';
import { Table } from 'element-ui/types/element-ui';
@Component({
  name: 'select-platform-product',
  components: { OsTable, OsTableQuery, OsPagination }
})
export default class SelectPlatformProduct extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false }) public syncedVisible!: boolean;
  @Prop({ default: true, type: Boolean, required: false }) public isMultiple!: boolean;
  public pageTotal = 0;
  public tableOption: OsTableOption<PlatformProductResource> = {
    height: '400px',
    loading: false,
    data: [],
    radioSelection: !this.isMultiple
  };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<PlatformProductResource>> = [
    {
      prop: 'productCode',
      label: 'product.productCode',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    { prop: 'productName', label: 'product.productName', minWidth: '150px', showOverflowTooltip: true },
    {
      prop: 'materialCategoryName',
      label: 'product.categoryName',
      showOverflowTooltip: true,
      minWidth: '150px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      },
      colSpan: 9
    },
    {
      type: 'Select',
      field: 'materialCategoryId',
      label: 'product.categoryName',
      option: {
        placeholder: 'product.selectCategoryName'
      },
      colSpan: 9,
      optionData: []
    }
  ];
  public selectedRows: Array<PlatformProductResource> = [];
  public selectedRow: PlatformProductResource | null = null;
  private queryForm: Query<{
    /**
     * 产品分类
     */
    materialCategoryId: number | null;
    keywords?: string;
  }> = {
    keywords: '',
    materialCategoryId: null
  };
  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };
  private selectedProductIds: Array<number> = [];
  private selectedProductId: number | null = null;

  public created(): void {
    if (this.isMultiple) {
      this.columnOption.unshift({
        type: 'selection',
        prop: 'id',
        label: '',
        reserveSelection: true
      });
    }
  }

  public dialogOpen(): void {
    this.reloadData();
    this.getProductClassify();
  }

  public dialogClosed(): void {
    this.resetQuery();
    this.clearSelection();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.getProduct();
  }

  public handlePaging(): void {
    this.getProduct();
  }

  public handleSelectionChange(selectedValues: Array<PlatformProductResource>): void {
    this.selectedRows = [...selectedValues];
  }

  public handleRadioSelectionChange(selectedValue: PlatformProductResource): void {
    this.selectedRow = selectedValue;
  }

  public onSubmit(): void {
    if (this.isMultiple) {
      this.$emit('confirm', this.selectedRows);
    } else {
      this.$emit('confirm', this.selectedRow);
    }
    this.syncedVisible = false;
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public get radioConfirmDisabled(): boolean {
    return !this.selectedRow;
  }

  public get selectedNumTemplate(): string {
    return `(${this.selectedRows.length}/${this.pageTotal})`;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    if (this.isMultiple) {
      ((this.$refs.productTable as OsTable).tableRef as Table).clearSelection();
    } else {
      (this.$refs.productTable as OsTable).clearRadioSelection();
    }
  }

  private getProduct(): void {
    this.tableOption.loading = true;
    productService
      .getAllUsingPlatformProduct(this.queryForm as PlatformProductResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        if (this.pageTotal === 0) {
          this.pageTotal = res.total;
        }
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getProductClassify(): void {
    productService
      .getAllUsingClassify()
      .then(res => {
        const queryOption = this.queryItemsOption.find(x => x.field === 'materialCategoryId');
        if (!queryOption) {
          return;
        }
        queryOption.optionData = res.map(x => {
          return { label: x.categoryName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private resetQuery(): void {
    this.queryForm = {
      keywords: '',
      materialCategoryId: null
    };
  }
}
