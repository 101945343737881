import { roleService } from '@/api';
import { PermissionResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Message, Tree } from 'element-ui';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  name: 'permission-config'
})
export default class PermissionConfig extends Vue {
  /**
   * 控制dialog的显示隐藏，默认为false(不显示)
   */
  @PropSync('visible', { default: false, required: true }) public syncedVisible!: boolean;
  @Prop({ type: Object, required: true })
  public configParams!: { roleName: string; roleId: number };

  public title = 'role.permissionConfig';
  public resourceIdList: Array<number> = [];
  public treeOptions: Array<PermissionResource> = [];
  public submitLoading = false;

  public defaultTreeProps = {
    children: 'children',
    label: 'name'
  };

  public treeControl = {
    /**
     * 全部展开
     */
    fold: true,
    /**
     * 选中全部
     */
    selectAll: false
  };

  public dialogOpen(): void {
    Promise.all([this.getPermissionResource(), this.getPermissionByRoleId()])
      .then(resArr => {
        this.treeOptions = resArr[0] || [];
        const leafs: Array<number> = this.getAllLeaf(resArr[1]);
        this.$nextTick(() => {
          (this.$refs.permissionTree as Tree).setCheckedKeys(leafs);
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    this.resetForm();
  }

  public async onSubmit(): Promise<void> {
    this.setLoading(true);
    try {
      const selectedKeys = (this.$refs.permissionTree as Tree)
        .getHalfCheckedKeys()
        .concat((this.$refs.permissionTree as Tree).getCheckedKeys());
      await roleService.configurationPermission({
        roleId: this.configParams.roleId,
        resourcesIdList: selectedKeys
      });
      Message.success(translation('operationRes.editSuccess'));
      this.closeDialog();
    } catch (error) {
      messageError(error);
    } finally {
      this.setLoading(false);
    }
  }

  public foldChange(value: boolean): void {
    (this.$refs.permissionTree as Tree).store._getAllNodes().forEach(x => {
      x.expanded = value;
    });
  }

  public checkAllChange(value: boolean): void {
    (this.$refs.permissionTree as Tree).setCheckedNodes(value ? this.treeOptions : []);
  }

  public resetForm(): void {
    this.treeControl = {
      fold: true,
      selectAll: false
    };
    this.resourceIdList = [];
  }

  private setLoading(value: boolean): void {
    this.submitLoading = value;
  }

  private closeDialog(): void {
    this.syncedVisible = false;
  }

  private async getPermissionResource(): Promise<Array<PermissionResource>> {
    return roleService.getAllUsingPermissionResource();
  }

  private async getPermissionByRoleId(): Promise<Array<PermissionResource>> {
    return roleService.getPermissionByRoleId(this.configParams.roleId);
  }

  /**
   * 获取树的所有叶子节点
   * @param tree 树
   * @returns
   */
  private getAllLeaf(tree: Array<PermissionResource>): Array<number> {
    const result: Array<number> = [];
    function getLeaf(data: Array<PermissionResource>): void {
      data.forEach(item => {
        if (!item.children || item.children.length === 0) {
          result.push(item.id);
        } else {
          getLeaf(item.children);
        }
      });
    }
    getLeaf(tree);
    return result;
  }
}
